@import "variables.css";
@import "animations.css";

#academia-teaching,
#academia-phd,
#academia-msc {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#academia-teaching-md,
#academia-phd-md,
#academia-msc-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}

#academia-phd-md {
  white-space: normal;
}

#academia-teaching-md img,
#academia-phd-md img,
#academia-msc-md img {
  width: 100%;
}

#academia-teaching-md table,
#academia-teaching-md tr,
#academia-teaching-md td,
#academia-teaching-md th,
#academia-phd-md table,
#academia-phd-md tr,
#academia-phd-md td,
#academia-phd-md th,
#academia-msc-md table,
#academia-msc-md tr,
#academia-msc-md td,
#academia-msc-md th {
  border: 1px solid #555;
}
