@import "variables.css";

a, 
a.context-link, 
a.main-link,
a.footer {
  color: var(--links-colour-link);
  text-decoration: none;
  text-align: center;
}

a:visited,
a.context-link:visited,
a.main-link:visited,
a.footer:visited {
  color: var(--links-colour-visited);
}

a:hover,
a.context-link:hover,
a.main-link:hover,
a.footer:hover {
  color: var(--links-colour-hover);
}

a:active,
a.context-link:active,
a.main-link:active,
a.footer:active {
  color: var(--links-colour-active);
}

a.content {
  color: var(--links-content-link);
}

a.content:visited {
  color: var(--links-content-visited);
}

a.content:hover {
  color: var(--links-content-hover);
}

a.content:active {
  color: var(--links-content-active);
}

p#menu-burger {
  color: var(--menu-burger-colour);
  font-size: 4rem;
  margin: 0;
  padding: 0;

  line-height: 0.57;
}

p#menu-burger:hover {
  color: var(--links-colour-hover);
}

p#menu-burger:active {
  color: var(--links-colour-active);
}

button.context-link {
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 0;

  font-size: 1rem;

  color: var(--links-colour-link);
}

button.context-link:hover {
  color: var(--links-colour-hover);
}

button.context-link:active {
  color: var(--links-colour-active);
}

#main-nav {
  display: grid;
  grid-template-rows: 1rem auto;

  position: fixed;
  top: 0;
  bottom: 0;
  right: var(--menu-width-negative);

  z-index: 2;

  padding: 1rem;

  width: var(--menu-width);

  background-color: var(--menu-colour);
  transition: transform .8s ease-in-out;
}

button.main-link,
button.main-link-close {
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  margin: 0;
  padding: 0;

  font-size: 1rem;

  color: var(--links-colour-link);
}

button.main-link-close {
  text-align: right;
}

button.main-link:hover,
button.main-link-close:hover {
  color: var(--links-colour-hover);
}

button.main-link:active,
button.main-link-close:active {
  color: var(--links-colour-active);
}

#main-nav.open {
  transform: translateX(var(--menu-width-negative));
}

#main-nav.close {
  transform: translateX(var(--menu-width));
}

#main-items {
  display: grid;
  place-items: start start;
}

.expander {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media screen and (max-width: 768px) {
  #main-nav {
    right: var(--menu-width-negative-small);
    width: var(--menu-width-small);
  }

  #main-nav.open {
    transform: translateX(var(--menu-width-negative-small));
  }
  
  #main-nav.close {
    transform: translateX(var(--menu-width-small));
  }
}

