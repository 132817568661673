@import "variables.css";
@import "animations.css";

#poetry-averse,
#poetry-balls,
#poetry-bands,
#poetry-brighton,
#poetry-difficult,
#poetry-dreamland,
#poetry-eton,
#poetry-golden-brown,
#poetry-gregorian,
#poetry-if,
#poetry-i-kneel,
#poetry-ironies,
#poetry-kane,
#poetry-lamb,
#poetry-love,
#poetry-magic,
#poetry-meaning,
#poetry-milk-after-ivor,
#poetry-moles,
#poetry-monkey,
#poetry-not-christmas,
#poetry-nuts,
#poetry-orgreave,
#poetry-pointless,
#poetry-prudictive,
#poetry-santa-claus,
#poetry-sea-glass,
#poetry-soon-to-be-73,
#poetry-soup,
#poetry-thankless,
#poetry-the-road,
#poetry-winter {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#poetry-balls-md,
#poetry-bands-md,
#poetry-brighton-md,
#poetry-eton-md,
#poetry-gregorian-md,
#poetry-i-kneel-md,
#poetry-ironies-md,
#poetry-kane-md,
#poetry-lamb-md,
#poetry-love-md,
#poetry-magic-md,
#poetry-meaning-md,
#poetry-milk-after-ivor-md,
#poetry-moles-md,
#poetry-monkey-md,
#poetry-nuts-md,
#poetry-pointless-md,
#poetry-prudictive-md,
#poetry-santa-claus-md,
#poetry-sea-glass-md,
#poetry-soup-md,
#poetry-thankless-md,
#poetry-the-road-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}

#poetry-averse-md,
#poetry-difficult-md,
#poetry-dreamland-md,
#poetry-golden-brown-md,
#poetry-if-md,
#poetry-not-christmas-md,
#poetry-orgreave-md,
#poetry-soon-to-be-73-md,
#poetry-the-road-md,
#poetry-winter-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: normal;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}

#poetry-averse-md table,
#poetry-difficult-md table,
#poetry-dreamland-md table,
#poetry-golden-brown-md table,
#poetry-if-md table,
#poetry-not-christmas-md table,
#poetry-orgreave-md table,
#poetry-soon-to-be-73-md table,
#poetry-soup-md table,
#poetry-the-road-md table,
#poetry-winter-md table {

  border-spacing: var(--table-spacing);
}

#poetry-averse-md th,
#poetry-difficult-md th,
#poetry-dreamland-md th,
#poetry-golden-brown-md th,
#poetry-if-md th,
#poetry-not-christmas-md th,
#poetry-orgreave-md th,
#poetry-soon-to-be-73-md th,
#poetry-soup-md th,
#poetry-the-road-md th,
#poetry-winter-md th {
  border-bottom: 1px solid grey;
}
