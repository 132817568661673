@import "variables.css";
@import "animations.css";

#stories-change,
#stories-frederick,
#stories-freedom,
#stories-gratitude,
#stories-minusculus,
#stories-the-druid,
#stories-the-earth-and-the-moon,
#stories-the-hope-of-gods,
#stories-the-knucker,
#stories-the-millwright {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#stories-change-md,
#stories-frederick-md,
#stories-freedom-md,
#stories-gratitude-md,
#stories-minusculus-md,
#stories-the-druid-md,
#stories-the-earth-and-the-moon-md,
#stories-the-hope-of-gods-md,
#stories-the-knucker-md,
#stories-the-millwright-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
