@import "variables.css";
@import "animations.css";

#books-my-abi,
#books-stories-from-the-campfire {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
}

#books-my-abi-md,
#books-stories-from-the-campfire-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
