@import "variables.css";
@import "animations.css"; 

#about-animated,
#about-cv,
#about-contact, 
#about-info {
  display: grid;
  place-items: center center;
  height: 100vh;

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#about-animated-md,
#about-cv-md,
#about-contact-md,
#about-info-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
