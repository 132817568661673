@import "variables.css";
@import "animations.css";

#apps-mark,
#apps-storymaker,
#apps-rectangles,
#apps-calculator {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#apps-mark-md,
#apps-storymaker-md,
#apps-rectangles-md,
#apps-calculator-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
