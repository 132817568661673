@import "variables.css";

#blog-25032023,
#blog-26032023,
#blog-31032023,
#blog-12042023 {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#blog-25032023-md,
#blog-26032023-md,
#blog-31032023-md,
#blog-12042023-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}


