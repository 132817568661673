@import "variables.css";
@import "animations.css";

#flash-eric,
#flash-fortunes,
#flash-wisdom {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#flash-eric-md,
#flash-fortunes-md,
#flash-wisdom-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
