@import "variables.css";

table,
tr,
td, 
th {
  color: var(--text-colour);
  border: none
}

button {
  margin: 0;
  padding: 2px 5px 2px 5px;
}

#not-found-md
{
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
