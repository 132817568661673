@import "variables.css";
@import "animations.css";

audio {
  filter: var(--audio-filter);
  height: 25px;
}

#songs-college-campus,
#songs-do-what-i-want,
#songs-echo-chamber,
#songs-if,
#songs-i-remember-when,
#songs-on-the-street,
#songs-whats-wrong-with-now {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#songs-college-campus-md,
#songs-do-what-i-want-md,
#songs-echo-chamber-md,
#songs-if-md,
#songs-i-remember-when-md,
#songs-on-the-street-md,
#songs-whats-wrong-with-now-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}
