@import "variables.css";

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: var(--title-text-colour);
}

h1 {
  margin: 1rem 0 0 0;
}

p {
  color: var(--text-colour);
  
  margin: 0;
  padding: 0;

  line-height: 1.5;
  
  font-size: 1rem;
}

span.content-link-title {
  color: var(--text-colour);
  font-size: var(--content-link-size);
}

span.subText {
  color: var(--text-colour);
  font-size: 1rem;
}

ul {
  margin: 0;
  white-space: normal;
}

li {
  color: var(--text-colour);
}

.subText {
  font-size: 1rem;
}

