@import "variables.css";
@import "animations.css";

#misc-another-great-escape,
#misc-champions-of-europe,
#misc-declans-lingerie,
#misc-jokes,
#misc-my-great-escape,
#misc-patience,
#misc-scotland-vs-england,
#misc-west-ham-vs-everton,
#misc-wind,
#misc-world-cup-semi {
  display: grid;
  place-items: center center;
  height: 100vh;

  font-size: var(--content-link-size);

  background: var(--content-link-colour);
  animation: backgroundAnimation 10s ease infinite;
}

#misc-another-great-escape-md,
#misc-champions-of-europe-md,
#misc-declans-lingerie-md,
#misc-jokes-md,
#misc-my-great-escape-md,
#misc-patience-md,
#misc-scotland-vs-england-md,
#misc-west-ham-vs-everton-md,
#misc-wind-md,
#misc-world-cup-semi-md {
  padding: var(--header-height) 2rem 0 2rem;
  white-space: pre-wrap;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background: var(--content-colour);
}

#misc-another-great-escape-md img,
#misc-champions-of-europe-md img,
#misc-declans-lingerie-md img,
#misc-jokes-md img,
#misc-my-great-escape-md img,
#misc-patience-md img,
#misc-scotland-vs-england-md img,
#misc-west-ham-vs-everton-md img,
#misc-wind-md img,
#misc-world-cup-semi-md img {
  width: 100%;
}
