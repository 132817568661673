:root { 
  --content-link-size: 1.4rem;

  --menu-width-small: 100px;
  --menu-width-negative-small: -100px;
  --menu-width: 200px;
  --menu-width-negative: -200px;

  --header-height: 3rem;
  --footer-height: 4rem;

  --table-spacing: 40px 5px;

  --audio-filter: brightness(100%);
}

:root[data-theme="light"] {

  --header-colour: #FFFDD0;
  --footer-colour: #FFFDD0;
  --menu-colour: #fbf7e2;
  --menu-burger-colour: #000000;

  --title-text-colour: #000000;
  --text-colour: #000000;
  --footer-text-colour: #000000;

  --content-link-colour: linear-gradient(115deg, #FFFDF2, #FFFFF0);
  --content-colour: linear-gradient(115deg, #FFFFF0, #FFFDF2);

  --links-content-link: red;
  --links-content-visited: #FF6347;
  --links-content-hover: #FF7F50;
  --links-content-active: #FF4500;

  --links-colour-link: red;
  --links-colour-visited: #FF6347;
  --links-colour-hover: #FF7F50;
  --links-colour-active: #FF4500;

  --button-border-colour: black;
}

:root[data-theme="dark"] {

  --header-colour: #00224D;
  --footer-colour: #00224D;
  --menu-colour: #5D0E41;
  --menu-burger-colour: #ffffff;

  --title-text-colour: #ffffff;
  --text-colour: #ffffff;
  --footer-text-colour: #ffffff;

  --content-link-colour: linear-gradient(115deg, #A0153E, #FF204E);
  --content-colour: linear-gradient(115deg, #FF204E, #A0153E);

  --links-colour-link: lightskyblue;
  --links-colour-visited: rgb(124, 200, 247);
  --links-colour-hover: rgb(119, 191, 235);
  --links-colour-active: rgb(81, 161, 211);

  --links-colour-link: lightskyblue;
  --links-colour-visited: rgb(125, 187, 226);
  --links-colour-hover: rgb(101, 167, 209);
  --links-colour-active: rgb(100, 156, 191);

  --button-border-colour: white; 

  --audio-filter: brightness(50%);
}