@import "variables.css";

#header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  box-sizing: border-box;

  position: fixed;
  top: 0;

  padding: 0 2rem 0.5rem 2rem;
  margin: 0;

  height: var(--header-height);
  width: 100vw;
  margin-right: calc(100vw - 100%);

  background-color: var(--header-colour);
  color: var(--text-colour);
}

#header-context {
  display: grid;
  place-items: end start;
}

#header-context > div {
  display: grid;
  place-items: center center;
}

#header-title {
  color: var(--title-text-colour);
  display: grid;
  place-items: end center;
}

#header-main {
  display: grid;
  place-items: end end;
}

#header-main > div {
  display: grid;
  place-items: center center;
}
