@import "variables.css";

#footer {
  display: grid;
  place-items: center center;
  grid-template-rows: auto auto;
  
  height: var(--footer-height);
  width: 100vw;
  margin-right: calc(100vw - 100%);

  box-sizing: border-box;

  background-color: var(--footer-colour);
  color: var(--text-colour);
}

#footer-content {
  display: grid;
  place-items: center center;
  grid-template-columns: 1fr 1fr 1fr;
}


button#footer-toggle {
  color: var(--footer-text-colour);
  border-radius: 10px;
  border: none;

  background-color: var(--content-active-colour);
}

button#theme-toggle {
  font-size: 1rem;
  color: var(--footer-text-colour);
  border-radius: 10px;
  border: 1px solid var(--button-border-colour);

  background-color: var(--content-active-colour);
}

button#theme-toggle:hover {
  color: var(--links-colour-hover);
}

button#theme-toggle:active {
  color: var(--links-colour-active);
}

#footer p {
  color: var(--footer-text-colour);
}
